import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Product from "../vo/Product"
import PaymentMethod from "../vo/PaymentMethod"
import DeliveryMethod from "../vo/DeliveryMethod"

const CHANGE_EVENT = "product_change" // chenge evnetを定数にする
const PAYMENT_METHOD_LOADED = "payment_method_loaded"
///Params
var _list = [] // 初期化
var _detail = null // 初期化
var _payment_method = []
var _delivery_method = []
///BODY
var setList = (list) => {
  _list = _list.concat(list)
}

var setDetail = (detail) =>  {
  _detail = detail
}

var setPaymentMethod = (list) =>{
  const __list = list.map((item) => {
    return ProductStore.createPaymentMethodRecord(item)
  })
  _payment_method = __list
}

var setDeliveryMethod = (list) =>{

  const __list = list.map((item) => {
    return ProductStore.createDeliveryMethodRecord(item)
  })
  _delivery_method = __list
}

var ProductStore = assign({},EventEmitter.prototype,{

  getList(){
    return _list
  },

  getDetail(){
    return _detail
  },

  getPaymentMethod(){
    return _payment_method
  },

  getDeliveryMethod(){

    return _delivery_method
  },

  getDeliveryPrice(area){

    const _list = _delivery_method[0].price.filter(item => Number(item.id) === Number(area) )
    return _list.length > 0 ? _list[0].price: null
  },

  getPaymentMethodById(id){
    const _list = _payment_method.filter(item => Number(item.id) === Number(id) )
    return _list.length > 0 ? _list[0]: null
  },

  createRecord(item){
      return Product({
        id:item.id,
        title: item.title,
        sub_title: item.sub_title,
        description:item.description,
        price:item.price,
        category:item.category,
        shop_id:item.shop_id,
        media:item.media,
        spec:item.spce
        }
      )
  },


  createDeliveryMethodRecord(item){
    return DeliveryMethod({
      id: item.id,
      name: item.name,
      free_ship_price:item.free_ship_price,
      price:item.price
    })
  },

  createPaymentMethodRecord(item){

      return PaymentMethod({
        id: item.id,
        pay_type: item.method_type,
        name: item.name
      })
  },

  ///EMIT/ADD/REMOVE
  emitChange(){ // 何かアクションがあったことを知らせる
    this.emit(CHANGE_EVENT)
  },

  addChangeListener(callback){ // リスナーに追加
    this.on(CHANGE_EVENT, callback)
  },

  removeChangeListener(callback){ // リスナーから削除
    this.removeListener(CHANGE_EVENT, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PRODUCT_GET_LIST:
      setList(action.list);
      ProductStore.emitChange()
      break;

    case Constants.PRODUCT_GET_ITEM:
      setDetail(action.item);
      ProductStore.emitChange()
      break;
    case Constants.PAYMENT_MTHOD_LOADED :
      setPaymentMethod(action.list)
      ProductStore.emitChange(PAYMENT_METHOD_LOADED)
      break;
    case Constants.DELIVERY_MTHOD_LOADED :
      setDeliveryMethod(action.list)
      ProductStore.emitChange()
      break;

    default:
      // noGET_DETAIL
  }
})
export default ProductStore
