import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Product from "../vo/Product"
import PaymentMethod from "../vo/PaymentMethod"
import DeliveryMethod from "../vo/DeliveryMethod"

const CHANGE_EVENT = "change" // chenge evnetを定数にする


var _item = null // 初期化
var _payment_method = null

var setItem = (item) =>  {
  _item = item
}
var setPaymentMethod = (payment) => {
  _payment_method = payment
}

var TradeStore = assign({},EventEmitter.prototype,{


  getItem(){
    return _item
  },

  getPaymentMethodId(){
    if(_payment_method)
      return _payment_method.id
    else return null
  },

  getPayment(){
    return _payment_method
  },

  getPaymentType(){
    if(_payment_method)
      return _payment_method.pay_type
    else return null
  },


  ///EMIT/ADD/REMOVE
  emitChange(){ // 何かアクションがあったことを知らせる
    this.emit(CHANGE_EVENT)
  },

  addChangeListener(callback){ // リスナーに追加
    this.on(CHANGE_EVENT, callback)
  },

  removeChangeListener(callback){ // リスナーから削除
    this.removeListener(CHANGE_EVENT, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.TRADE_LOADED:
      setItem(action.item);
      TradeStore.emitChange()
      break;
    case Constants.UPDATE_PAYMENT_METHOD:

      setPaymentMethod(action.payment);
      TradeStore.emitChange()
      break;


    default:
      // noGET_DETAIL
  }
})
export default TradeStore
