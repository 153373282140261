import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var CustomerActions = {

  update(type, name,value){

    AppDispatcher.dispatch({
      actionType: Constants.CUSTOMER_INFO_FORM_UPDATE,
      name: name,
      value:value,
      type:type
    })

  },

  saveOnCookie(info, name){
    cookie.save(name,JSON.stringify(info),{ path: '/' })
    AppDispatcher.dispatch({
      actionType: Constants.CUSTOMER_INFO_SAVE_COOKIE
    })
  },

  extractFromCookie(name){
    var customer_info = cookie.load(name)

    if(name === "delivery" && (!customer_info || !customer_info.name) ){
      customer_info = cookie.load("customer")
    }

    AppDispatcher.dispatch({
      actionType: Constants.CUSTOMER_INFO_GET,
      item:customer_info,
      name:name
    })
  },

  getInfo(token){

    fetch(config.get("API_CUSTOMER") + config.get("API_CUSTOMER_INFO"), {
      method: 'GET',
      headers:{
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {
      if(data){

        AppDispatcher.dispatch({
          actionType: Constants.CUSTOMER_INFO_GET, // 誰に
          item: data.customer_info
        })
      }
    })

  },

  setDeliveryFromCustomer(){
    AppDispatcher.dispatch({
      actionType: Constants.SET_DELIVERY_FROM_CUSTOMER
    })

  }





}

export default CustomerActions
