import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change" // chenge evnetを定数にする
var ACCOUNT_CREATED = "account_created"


///AUTH
var _is_login = false
var _token = ""
//var _refresh_token = ""
var _scope = null

var _user_info = null
/*
*  ここから処理本体を書き始める
*/

var auth = (is_auth,token,refresh_token, scope) => {

  _is_login = is_auth
  _token = token
  //_refresh_token = refresh_token
  _scope = scope
}

var removeAccountObject = () =>{
  _is_login = false
  _token = null
  //_refresh_token = null
}




var AuthStore = assign({},EventEmitter.prototype,{

  /*getAll(){ // 今のtodo全てを返す
    return _todos
  },*/

  isAuth(){
    return _is_login
  },

  getToken(){
    return _token
  },

  getScope(){
    return _scope
  },

  getUserInfo(){
    return _user_info
  },



  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.AUTH:
      auth(action.is_auth, action.token, action.refresh_token, action.scope);
      AuthStore.emitChange()
      break;
    case Constants.NO_AUTH_INFO:
      removeAccountObject()
      AuthStore.emitChange()
      break;
  /*  case Constants.ACCOUNT_UPDATE:

      setDetail(action.name,action.value)
      AuthStore.emitChange(ACCOUNT_UPDATE)
      break;*/
    case Constants.ACCOUNT_CREATED:
      AuthStore.emitChange(ACCOUNT_CREATED)
      break;

  /*  case Constants.AUTH_LOGOUT:
      AuthStore.emitChange(ACCOUNT_UPDATE)
      break; */

    default:
      // no
  }
})
export default AuthStore
