import React from "react"


export default class CartItem extends React.Component {

  constructor(){
    super()
    this.addQuantity = this.addQuantity.bind(this)
    this.reduceQuantity = this.reduceQuantity.bind(this)
    this.state = {
      item:null
    }

  }

  componentDidMount(){
  }

  addQuantity(){
    this.props.quantityUpdate(this.props.item.id, this.props.item.quantity + 1)
  }
  reduceQuantity(){
    this.props.quantityUpdate(this.props.item.id, this.props.item.quantity - 1)
  }
  componentWillUnmount(){

  }



  render(){
    const item = this.props.item
    if(item == null) return null
    //console.log(item)
    return(
      <tr key={"tr_" + item.id} >
        <td>{item.product.title}</td>
        <td className="t-right">{item.product.price.toLocaleString()}円</td>
        <td className="t-right quantity"><i className="far fa-minus-square" onClick={this.reduceQuantity}></i> {item.quantity} {item.product.unit} <i onClick={this.addQuantity} className="far fa-plus-square"></i></td>
        <td className="t-right">￥{(item.quantity*item.product.price).toLocaleString()} {item.product.unit}円</td>
      </tr>
    )
  }


}
