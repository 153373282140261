import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';
import { format } from 'date-fns'
import config from 'react-global-configuration';
import AuthStore from "../../stores/AuthStore"
import ProductStore from '../../stores/ProductStore'
import TradeActions from "../../actions/TradeActions"
import RadioBox from "./Radiobox"
import  "../../styles/cart.css"

function PaymentInfo(props:PropsValue) {

  const [items, setItems] = useState([])
  const [payment_id, setId] = useState(null)
  const stablePaymentMethodHandler = useCallback(paymentMethodChangeHandle,[])

  useEffect(() => {
    ProductStore.addChangeListener(setPaymentMethod,"payment_method_loaded")
    return function cleanup() {
      ProductStore.removeChangeListener(setPaymentMethod,"payment_method_loaded")
    };
  },[stablePaymentMethodHandler]);

  useEffect(() => {
    setPaymentMethod()
  },[payment_id,stablePaymentMethodHandler]);

  function setPaymentMethod(){
    const list = ProductStore.getPaymentMethod().map(pm => {
      return {value:Number(pm.id), text:pm.name}
    })
    setItems(<RadioBox items={list} name="payment" selected={Number(payment_id)} changeHandler={paymentMethodChangeHandle}/>)
  }

  function paymentMethodChangeHandle(name,value){
    setId(value)
    const payment = ProductStore.getPaymentMethodById(value)
    TradeActions.updatePaymentMethod(payment)
  }

  return(
    <div className="form">
      <div className="form-box-item">
        <label className="label-def" htmlFor="name">支払い方法</label>
        {items}
      </div>
    </div>
    )
}

export default PaymentInfo
