import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var ProductActions = {

  getList(){

    fetch(config.get("API_PATH") + config.get("API_PRODUCTS_LIST")  , {
      method: 'GET',
      headers: {'shop_key': config.get("SHOP_KEY") }
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {
      console.log(data)
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.PRODUCT_GET_LIST, // 誰に
          list: data.products
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }

    })
  },


  getDetail(id){

    fetch(config.get("API_PATH") + config.get("API_PRODUCT_DETAIL") + id , {
      method: 'GET',
      headers: {'shop_key': config.get("SHOP_KEY") }
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {
      //console.log(data)
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.PRODUCT_GET_ITEM, // 誰に
          item: data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }

    })


  },

  getPaymentMethod(ids){

    const paroduct = "?product_id=" + ids.join(",")

    fetch(config.get("API_PATH") + config.get("API_PAYMENT_METHOD_LIST")  + paroduct , {
      method: 'GET',
      headers: {'shop_key': config.get("SHOP_KEY")}
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {

      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.PAYMENT_MTHOD_LOADED, // 誰に
          list: data.payment_methods
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }

    })
  },
  getDeliveryMethod(ids){

    const paroduct = "?product_id=" + ids.join(",")
    fetch(config.get("API_PATH") + config.get("API_DELIVERY_METHOD_LIST")  + paroduct , {
      method: 'GET',
      headers: {'shop_key':  config.get("SHOP_KEY")}
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.DELIVERY_MTHOD_LOADED, // 誰に
          list: data.delivery_methods
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }

    })
  }

}

export default ProductActions
