import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import {addMinutes} from "date-fns"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

import CartActions from './CartActions'
var AuthActions = {

  setAuth(need_create = false){

    var is_auth = false;
    let token = cookie.load('token')
    let refresh_token = cookie.load('refresh_token')
    let scope = cookie.load('scope')


    if(token != undefined && token != ""){
      is_auth = true

      AppDispatcher.dispatch({
        actionType: Constants.AUTH, // 誰に
        is_auth: is_auth,
        token: token,
        scope:scope,
        refresh_token: refresh_token
      })
    }else{
      if(need_create){
        AuthActions.createAndAuth()
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.NO_AUTH_INFO
        })
      }
    }
  },

  createAndAuth(){
    console.log("create and ex1")
    fetch(config.get("API_CUSTOMER") + config.get("API_CREATE_TENTITIVE"), {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'shop_key': config.get("SHOP_KEY")
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {
      //getToken

      if(data.id){
        AuthActions.auth(data.username,"","tmp_customer")
      }else{

      }
    })
  },

  createAndExtractFromCookie(){
    console.log("create and ex")

    fetch(config.get("API_CUSTOMER") + config.get("API_CREATE_TENTITIVE"), {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'shop_key': config.get("SHOP_KEY")
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {
      //getToken
      if(data.id){
        AuthActions.auth(data.username,"","tmp_customer",CartActions.extractFromCookie)
      }else{

      }
    })
  },

  createAccount(username,password,type){

    var data = {username:username,
                password:password,
                account_type:type};

    fetch(config.get("API_CUSTOMER") + config.get("API_CREATE_ACCOUNT"), {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'shop_key': config.get("SHOP_GROUP_KEY")
      },
      body: JSON.stringify(data)
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 412){
        AuthActions.auth(username,password,"nml_customer")
        return null
      }else{
        return null
      }
    }).then(function(data) {
      //getToken

      if(data && data.status == 1){
        AuthActions.auth(username,password,"nml_customer")
      }else{

      }
    })
  },



  auth(loginname,password,scope,onAuthFunction = null){
    console.log(loginname)
    console.log(onAuthFunction)
    var auth_data = new FormData()
    auth_data.append('grant_type', "password")
    auth_data.append('username', loginname)
    auth_data.append('password', password)
    auth_data.append('client_id', config.get("SHOP_ID"))
    auth_data.append('client_secret', config.get("SHOP_GROUP_KEY"))
    auth_data.append('scope', scope)

    fetch(config.get("API_AUTH") + config.get("API_LOGIN"), {
      method: 'POST',
      body: auth_data
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {

      if(data && data.access_token){
        const  is_auth = true;
        const token = data.access_token
        const refresh_token = data.refresh_token
        const scope = data.scope

        if(cookie.load('scope') == "tmp_user"){
          CartActions.changeOwner(token,cookie.load('scope'),token,cookie.load('username'))
        }
        const expires = addMinutes(new Date(), 60)

        cookie.save('token', token, { path: '/', expires:expires })
        cookie.save('refresh_token', refresh_token, { path: '/', expires:expires  })
        cookie.save('scope', scope, { path: '/', expires:expires  })
        cookie.save('username', loginname, { path: '/', expires:expires  })

        AppDispatcher.dispatch({
          actionType: Constants.AUTH, // 誰に
          is_auth: is_auth,
          token: token,
          scope:scope,
          refresh_token: refresh_token
        })
        if(onAuthFunction){
          console.log("auth")
          onAuthFunction(token)
        }
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }
    })
  },



  logout(){
    console.log("logout")
    cookie.remove('token',{ path: '/' })
    cookie.remove('refresh_token',{ path: '/' })
    cookie.remove('scope',{ path: '/' })

    AppDispatcher.dispatch({
      actionType: Constants.AUTH, // 誰に
      is_auth: false,
      token: null,
      scope:null,
      refresh_token: null
    })

  },


}

export default AuthActions
