import React, { useState, useEffect,useCallback } from 'react'
import '../../styles/header.css'

import logo from "../../images/logo_b.svg"

function Header(props:PropsValue) {


  return(
      <header>
        <div className="header-inner">
        <div className="logo"><a href="/"><img src={logo} alt="Sawayaka Sweet" /></a></div>
        <div className="sub is-pc">オンラインショップ</div>
        <div className="info">
          <div>医療法人 好生会 さわや家</div>
        </div>
        </div>

      </header>

  )
}

export default Header
