import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';

import IndexApp from './components/IndexApp'
import CartApp from './components/CartApp'
import ItemApp from './components/ItemApp'
import PrivacyApp from "./components/PrivacyApp"
import LawApp from "./components/LawApp"
import SuccessApp from "./components/SuccessApp"
config.set(configuration);

class App extends React.Component{


render(){
return(
    <div>
      <Switch>
        <Route exact path='/' component={IndexApp} />
        <Route exact path='/detail/:id' component={ItemApp} />
        <Route exact path='/cart' component={CartApp} />
        <Route exact path='/privacy' component={PrivacyApp} />
        <Route exact path='/law' component={LawApp} />
        <Route exact path='/success/:id' component={SuccessApp} />
      </Switch>
    </div>
)}}

ReactDOM.render(
  (<BrowserRouter basename="yuka"><App /></BrowserRouter>),
  document.getElementById('root')
)
