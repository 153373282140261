import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';
import { format } from 'date-fns'
import config from 'react-global-configuration';

import Header from "./parts/Header"
import Footer from "./parts/Footer"
import ProductActions from "../actions/ProductActions"
import CartActions from "../actions/CartActions"
import ProductStore from "../stores/ProductStore"

import Cart from "../vo/Cart"

import "../styles/product.css"


function ItemApp(props:PropsValue) {
  const { id } = useParams();
  const [item, setItem] = useState(null)
  const [images, setImages] = useState([])
  const stableProductChangeHandler = useCallback(productChangeHandler,[])
  useEffect(() => {
    ProductStore.addChangeListener(stableProductChangeHandler)
    ProductActions.getDetail(id)

    return function cleanup() {

    };

  },[]);

  function productChangeHandler(){
    ProductStore.removeChangeListener(stableProductChangeHandler)
    const _item = ProductStore.getDetail()
    setItem(_item)

    setImages(_item.medias.filter(media => media.media_key !== "main_image").map(media => {
      return(<div><img src={media.url} /></div>)
    })

    )
  }

  function addToCart(){
    var cart = Cart({
      product_id:id,
      quantity:1,
      shop_id:Number(config.get("SHOP_ID")),
      has_stock:true
    })
    CartActions.add(cart)
  }

  return(

    <div className="inner">
      <Header />

      <main className="product">

        <section className="main-content content product">
          <div className="left-side">
            <div className="title is-mob head">{item ? item.title : null}</div>

            <div className="image-box">
              {item ? <img src={item.medias[0].url} alt=""/> : null}
            </div>
            <div className="price">¥ {item ? Math.round(item.price * config.get("TAX")).toLocaleString() : null}円<span className="sml">(税込)</span></div>
            <div className="button-box">
              <button className="button-create" onClick={addToCart} ><i className="fas fa-shopping-cart"></i> カートに入れる</button>
            </div>
            <h2 className="photo">製造の様子</h2>
            <div className="photos">
              {images}
            </div>
          </div>
          <div className="right-side">
            <div className="title ">{item ? item.title : null}</div>
            <div className="sub-title">{item ? item.sub_title : null}</div>
            <div className="description" dangerouslySetInnerHTML={{
              __html: item ? item.description : null
            }} />
          </div>

        </section>

      </main>
      <Footer />
    </div>
  )
}

export default ItemApp
