import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import productActions from './ProductActions'

var CartActions = {


  add(product){
    const products = cookie.load('cart') ? cookie.load('cart')  : []
    products.push(product)
    cookie.save("cart", JSON.stringify(products), { path: '/' })
    window.location.href = "/cart"
  },

  update(products){
    cookie.save("cart", JSON.stringify(products), { path: '/' })
    window.location.href = "/cart"
  },

  extractFromCookie(token){
    console.log("extra]")
    const products = cookie.load('cart')
    if(products){

      for(var i = 0; i < products.length; i++){
        const _item = products[i]

        const item = {
              	"shop_id":Number(config.get("SHOP_ID")),
              	"product_id":Number(_item.product_id),
              	"quantity":Number(_item.quantity),
                "x_index":Number(_item.x_index),
                "y_index":Number(_item.y_index),
                "questions":_item.questions
              }

        fetch(config.get("API_CART") + config.get("API_CART_ADD"), {
          method: 'POST',
          headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },

          body: JSON.stringify(item)
        }).then(function(response) {
   
          if(response.status === 200){
            return response.json()
          }else if(response.status === 401){
            CartActions.clearCart(token)
          }else{
            CartActions.clearCart(token)
            AppDispatcher.dispatch({
              actionType: Constants.API_ERROR
            })
            return null
          }
        }).then(function(data) {
          if(data){

            cookie.remove('cart',{ path: '/' })
            CartActions.getList(token)
            //CartActions.getList(token)
            /*AppDispatcher.dispatch({
              actionType: Constants.CART_UPDATE, // 誰に
              list: data.items
            })*/
          }
        })

      }

    }
  },


  getList(token){
    //console.log(config.get("API_CART") + config.get("API_CART_LIST") + "?shop_id=" + shop_id)
    const shop_id = config.get("SHOP_ID")
    fetch(config.get("API_CART") + config.get("API_CART_LIST") + "?shop_id=" + shop_id, {
      method: 'GET',
      headers:{
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null

        //AuthActions.reAuth(CartActions.getList(token,shop_id)){
      }
    }).then(function(data) {

      if(data){

        const ids = data.items.map((item) => {
          return  item.product.id
        })
        if(ids.length > 0) {
          productActions.getPaymentMethod(ids,config.get("API_SHOP_KEY"))
          productActions.getDeliveryMethod(ids,config.get("API_SHOP_KEY"))
        }

        AppDispatcher.dispatch({
          actionType: Constants.CART_UPDATE, // 誰に
          list: data.items,
          shop_id:shop_id
        })
      }
    })

  },

  updateQuantity(token,id,quantity){
    const item = {'quantity':Number(quantity)}

    fetch(config.get("API_CART") + config.get("API_CART_UPDATE_QUANTITY") + id , {
      method: 'PATCH',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(item)

    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {

      if(data){
        CartActions.getList(token)
      }
    })

  },


  clearCart(token){

    cookie.remove('token',{ path: '/' })
    cookie.remove('refresh_token',{ path: '/' })
    cookie.remove('scope',{ path: '/' })
    cookie.remove('cart',{ path: '/' })

    fetch(config.get("API_CART") + config.get("API_CART_CLEAR") + "/" +  config.get("SHOP_ID"), {
      method: 'DELETE',
      headers:{
        'Authorization': 'Bearer ' + token
      }

    }).then(function(response) {
      if(response.status === 204){
        return true
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {
      console.log(data)
      window.location.href = "/"
    })

  },

  changeOwner(token,tmp_user){

    const item = {'tmp_user_id':tmp_user}
    fetch(config.get("API_CART") + config.get("API_CART_CHANGE_USER") , {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(item)

    }).then(function(response) {
      //console.log(response)
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {
      if(data){
        console.log("cart change owner")
      }
    })

  }



}

export default CartActions
