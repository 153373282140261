import React,{useState,useEffect,useCallback} from 'react'



/****
name : form name
selected: selected value
items:

  value :form value
  text : lavel html

 changeHandler
***/

function RadioBox(props:PropsValue) {

  const [items, setItems] = useState(null);
  const stableCreateTags = useCallback(createTags,[])

  useEffect(() => {
    stableCreateTags(props.selected, props.items)
  },[props.selected,stableCreateTags,props.items]);



  function createTags(selected,list) {
    const _items = list.map( item => {
      return (
        <div className="radio" key={"radio_" + props.name + "_" + item.value} ><input className="form-check" type="radio" onChange={event => checkHandler(event)} checked={selected === item.value ? true: false}  id={"radio" + props.name + "_" + item.value}  value={item.value} />
          <label className="radio-parts" htmlFor={"radio" + props.name + "_" + item.value} dangerouslySetInnerHTML={{__html: item.text }}>
          </label>
        </div>
      )
    })
    setItems(_items)
  }

  function checkHandler(e){
    var target_value = e.target.value
    props.changeHandler(props.name, target_value)
  }



  return(
    <div className={"radiobox-wrap input-box " + props.className}>
      {items}
    </div>

  )
}

export default RadioBox
