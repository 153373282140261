import AppDispatcher from "../dispatcher/AppDispatcher"

import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import CardStore from "../stores/CardStore"
import cookie from 'react-cookies'


var CardActions = {
  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.CARD_UPDATE, // 誰に
      name: name,
      value:value
    })
  },

  pay(token, trade_id, total_price, payment_id){
    const card = CardStore.getDetail()
    const _card = {
      number: card.cnumber,
      cvc: card.cvc,
      exp_month: card.month,
      exp_year:2000 +  Number(card.year)
    };


    window.Payjp.setPublicKey(config.get("PAY_JP_KEY"));

    window.Payjp.createToken(_card, function(status, response) {
      if (status === 200) {
        //p.setToken(response.id)
        CardActions.payexe(token,response.id,total_price,trade_id,payment_id)
      } else {
        AppDispatcher.dispatch({
          actionType: Constants.ERROR_CARD_VALIDATE, // 誰に
          message: "カード情報が確認できませんでした。"
        })
        console.log("error")
      };
    });
  },


  payexe(token,c_token,total_price,store_trade_id,payment_id){
    const data = {
      trade_id:store_trade_id,
	    payment_id:Number(payment_id),
	    amount:Number(total_price),
      card_token:c_token
    };


    fetch(config.get("API_CART") + config.get("PAY") , {
      method: 'POST',
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
     },
    body: JSON.stringify(data)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {

      if(data ){
        AppDispatcher.dispatch({
          actionType: Constants.CARD_PAIED
        })
        cookie.remove('trade_id',{ path: '/' })
        cookie.remove('total_price',{ path: '/' })
        cookie.remove('payment_method',{ path: '/' })
        
        window.location.href = "/success/" + store_trade_id
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.ERROR_CARD_VALIDATE, // 誰に
          message: "カード決済ができませんでした。code:400"
        })
      }
    });
  }

}

export default CardActions
