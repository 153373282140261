import keyMirror from "keymirror"

export default keyMirror({
  ///AUTH
  AUTH:null,
  NO_AUTH_INFO:null,

  ///CELL
  PRODUCT_GET_LIST: null,
  PRODUCT_GET_ITEM: null,

  PROFILE_GET_ITEM:null,

  CUSTOMER_INFO_GET:null,
  CUSTOMER_INFO_FORM_UPDATE:null,
  CUSTOMER_INFO_SAVE_COOKIE:null,
  SET_DELIVERY_FROM_CUSTOMER:null,

  CART_UPDATE:null,
  CART_SAVE_ON_COOKIE:null,
  CART_CLEAR:null,

  CARD_UPDATE:null,
  ERROR_CARD_VALIDATE:null,

  TRADE_CREATED:null,
  TRADE_LOADED:null,
  TRADE_List_LOADED:null,
  UPDATE_COMMENT:null,
  PAYMENT_MTHOD_LOADED:null,

  DELIVERY_MTHOD_LOADED:null,
  UPDATE_PAYMENT_METHOD:null,

  PARTY_GET_ITEM:null,

  

  //Path
  PATH_UPDATE:null,
  PATH_SAVE_ON_COOKIE:null,
  ///ERROR
  API_AUTH_ERROR:null,
  API_ERROR:null
})
