import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';

import Header from "./parts/Header"
import Footer from "./parts/Footer"
import "../styles/static.css"
import config from 'react-global-configuration';


  function LawApp(props:PropsValue) {


    return(
      <div className="inner">
        <Header />

        <main className="static">

          <section className="main-content content">
            <h1>特定商取引法に基づく表記</h1>

            <table className="info-table">
              <tr><th>店舗名</th><td>sawayaka  sweets</td></tr>
              <tr><th>販売業者</th><td>医療法人　好生会</td></tr>
              <tr><th>運営責任者</th><td>沢居　なおみ</td></tr>
              <tr><th>住所</th><td>店舗住所：〒436-0033　静岡県掛川市篠場779-2　<br/>
                      本社住所：〒432-8063　静岡県浜松市南区小沢渡町2195-2</td></tr>
              <tr><th>電話番号</th><td>0537-62-0620</td></tr>
              <tr><th>FAX番号</th><td>0537-62-0621</td></tr>
              <tr><th>商品代金以外の必要料金</th><td>消費税、送料、代金引換手数料</td></tr>

              <tr><th>注文方法</th><td>インターネット販売</td></tr>
              <tr><th>引渡し時期</th><td>【クレジットカード】ご注文確認後14営業日以内に発送<br/>【代金引換】ご注文確認後14営業日以内に発送</td></tr>
              <tr><th>お支払い期限</th><td>【クレジットカード】ご注文時<br/>【代金引換】商品受取時　</td></tr>
              <tr><th>お支払い方法</th><td>クレジットカード、代金引換</td></tr>
              <tr><th>返品期限</th><td>商品到着後７日以内とさせていただきます。</td></tr>
              <tr><th>返品交換について</th><td>商品発送後の返品・返却等はお受けいたしかねます。<br/>
                商品保証：商品に記載の賞味期限以内といたします。<br/>
                クレーム期限:商品到着後５営業日以内にご連絡下さい。<br/>
                返品送料:不良品・破損品の場合は当社負担。それ以外はお客様のご負担。
              </td></tr>

              <tr><th>公開メールアドレス</th><td>info-sawayaka@k-sawayaka.org</td></tr>
            </table>





          </section>

        </main>
        <Footer />
      </div>
    )
}

export default LawApp
