import { Record } from 'immutable'

const Cart = Record({
  id:null,
  product_id:null,
  quantity: null,
  shop_id:null,
  x_index:null,
  y_index:null,
  x_name:null,
  y_name:null,
  product:null,
  delivery_method:null,
  payment_method:null,
  questions:null
});
export default Cart;
