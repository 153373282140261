
const config = {
    LOGIN_URL:                  '/',

    API_PRODUCT_DETAIL:       '/products/',

    API_LINE_PATH:            '/line/token',
    API_LOGIN:                '/oauth/auth',
    API_PRODUCTS_LIST:        '/products',

    API_CREATE_TENTITIVE:     '/tentative',
    API_CREATE_ACCOUNT:       '/',
    API_CUSTOMER_INFO:        '/customers/info',
    API_CART_ADD:             '/items',
    API_CART_LIST:            '/items',
    API_CART_CLEAR:           '/items',
    API_CART_CHANGE_USER:     '/items/changeUser',
    API_CART_UPDATE_QUANTITY: '/items/',
    API_CART_TRADE_CREATE:    '/trades',
    API_CART_TRADE_GET:       '/trades',
    API_PAYMENT_METHOD_LIST:  '/payment_methods',
    API_DELIVERY_METHOD_LIST: '/delivery_methods',
    PAY:        '/pay',


    PAY_JP_KEY:         'pk_live_0dcb0c07a6caa7d98b1d5d1d',
    API_AUTH:           process.env.REACT_APP_API_AUTH_PATH ?  process.env.REACT_APP_API_AUTH_PATH : 'https://auth.scrasy.jp',
    API_PATH:           process.env.REACT_APP_API_CATALOG_PATH ? process.env.REACT_APP_API_CATALOG_PATH  : 'https://catalog.scrasy.jp',
    API_CART:           process.env.REACT_APP_API_CART_PATH ?  process.env.REACT_APP_API_CART_PATH : 'https://cart.scrasy.jp',
    API_CUSTOMER:        process.env.REACT_APP_API_CUSTOMER_PATH ?  process.env.REACT_APP_API_CUSTOMER_PATH : 'https://customer.scrasy.jp',

    ERROR_AUTH:     "メールアドレスまたはパスワードが一致しません",
    ERROR_DELETE:      "削除に失敗しました",
    ERROR_SYNC_0:   "同期に失敗しました。パスワード・日程を確認してください。",
    VIA_0: "公式" ,
    VIA_1: "一休" ,
    VIA_100: "その他" ,

    TAX:1.1,
    SHOP_ID:15,
    SHOP_KEY:"sawayaka_sw",
    SHOP_GROUP_KEY:"sawayakaip",

    PAYTYPE_1: '振込',
    PAYTYPE_2: 'クレジットカード',
    PAYTYPE_3: '一休',

    PREF_1 : '北海道',
    PREF_2 : '青森県',
    PREF_3 : '岩手県',
    PREF_4 : '宮城県',
    PREF_5 : '秋田県',
    PREF_6 : '山形県',
    PREF_7 : '福島県',
    PREF_8 : '茨城県',
    PREF_9 : '栃木県',
    PREF_10 : '群馬県',
    PREF_11 : '埼玉県',
    PREF_12 : '千葉県',
    PREF_13 : '東京都',
    PREF_14 : '神奈川県',
    PREF_15 : '新潟県',
    PREF_16 : '富山県',
    PREF_17 : '石川県',
    PREF_18 : '福井県',
    PREF_19 : '山梨県',
    PREF_20 : '長野県',
    PREF_21 : '岐阜県',
    PREF_22 : '静岡県',
    PREF_23 : '愛知県',
    PREF_24 : '三重県',
    PREF_25 : '滋賀県',
    PREF_26 : '京都府',
    PREF_27 : '大阪府',
    PREF_28 : '兵庫県',
    PREF_29 : '奈良県',
    PREF_30 : '和歌山県',
    PREF_31 : '鳥取県',
    PREF_32 : '島根県',
    PREF_33 : '岡山県',
    PREF_34 : '広島県',
    PREF_35 : '山口県',
    PREF_36 : '徳島県',
    PREF_37 : '香川県',
    PREF_38 : '愛媛県',
    PREF_39 : '高知県',
    PREF_40 : '福岡県',
    PREF_41 : '佐賀県',
    PREF_42 : '長崎県',
    PREF_43 : '熊本県',
    PREF_44 : '大分県',
    PREF_45 : '宮崎県',
    PREF_46 : '鹿児島県',
    PREF_47 : '沖縄県'
}
export default config;
