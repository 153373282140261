import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import CardActions from "./CardActions"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

var TradeActions = {


  save(token,trade){
    
    let trade_id = cookie.load('trade_id')
    let total_price = cookie.load('total_price')
    let payment_method = cookie.load('payment_method')

    if(trade_id && total_price && payment_method ) {
      CardActions.pay(token, trade_id, total_price, payment_method)
      return true
    }

    fetch(config.get("API_CART") + config.get("API_CART_TRADE_CREATE") , {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(trade)

    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {
      if(data){

        if(data.payment_method.pay_type === Number(2)){
        
          cookie.save('trade_id', data.id, { path: '/' })
          cookie.save('total_price', data.total_price, { path: '/' })
          cookie.save('payment_method', data.payment_method.id, { path: '/' })
          CardActions.pay(token, data.id, data.total_price, data.payment_method.id)
        }else{
          window.location.href = "/success/" + data.id
        }
        //  TradeActions.pay(token, trade_id, total_price, payment_id){
        //
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }
    })

  },


  getDetail(token,trade_id){

    fetch(config.get("API_CART") + config.get("API_CART_TRADE_GET") + "/" + trade_id, {
      method: 'GET',
      headers:{
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {

      if(data && data.status === 1){

        AppDispatcher.dispatch({
          actionType: Constants.TRADE_LOADED, // 誰に
          item: data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }
    })
  },

  getList(token){

    fetch(config.get("API_CART") + config.get("API_CART_TRADE_GET") , {
      method: 'GET',
      headers:{
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_AUTH_ERROR
        })
        return null
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
        return null
      }
    }).then(function(data) {
      if(data){

        AppDispatcher.dispatch({
          actionType: Constants.TRADE_List_LOADED, // 誰に
          list: data.trades
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR
        })
      }
    })

  },

  updateComment(c){
    AppDispatcher.dispatch({
      actionType: Constants.UPDATE_COMMENT,
      comment:c
    })
  },

  updatePaymentMethod(payment){
    AppDispatcher.dispatch({
      actionType: Constants.UPDATE_PAYMENT_METHOD,
      payment:payment
    })
  }



}

export default TradeActions
