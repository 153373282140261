import React from "react"
import config from 'react-global-configuration';


export default class PrefectureSelection extends React.Component {

  constructor(){
    super()

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  handleChange(e){
    const target = e.target
    this.props.updateForm(target.name,Number(target.value))
  }




  render(){
    const list_objects = []
    list_objects.push(<option value={0} key="pref_">-</option>)
    for(var i = 0; i < 47; i++){
      list_objects.push(<option value={i+1} key={"pref_" + i}>{config.get("PREF_" + (i+1))}</option>)
    }


    return(
      <div className="input-box">
        <select className="m-select m-form" name="area" value={this.props.value ? this.props.value : undefined } onChange={this.handleChange}>
        {list_objects}
        </select>
      </div>
    )
  }
}
