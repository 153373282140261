import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';

import Header from "./parts/Header"
import Footer from "./parts/Footer"
import "../styles/static.css"
import config from 'react-global-configuration';


  function PrivacyApp(props:PropsValue) {


    return(
      <div className="inner">
        <Header />

        <main className="static">

          <section className="main-content content">
            <h1>プライバシーポリシー</h1>

            <h2>1.個人情報の定義</h2>
            <p>「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの、及び他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをいいます。
            </p>
            <h2>2.個人情報の収集</h2>
            <p>当ショップでは商品のご購入、お問合せをされた際にお客様の個人情報を収集することがございます。<br/>
            収集するにあたっては利用目的を明記の上、適法かつ公正な手段によります。
            </p>
            <p>
            当ショップで収集する個人情報は以下の通りです。
            </p>
            <p>
            a)お名前<br/>
            b)ご住所<br/>
            c)お電話番号<br/>
            d)メールアドレス<br/>
            e)パスワード<br/>
            f)配送先情報<br/>
            g)当ショップとのお取引履歴及びその内容<br/>
            h)上記を組み合わせることで特定の個人が識別できる情報
            </p>
            <h2>3.個人情報の利用</h2>
            <p>
            当ショップではお客様からお預かりした個人情報の利用目的は以下の通りです。
            </p>
            <p>
            a)ご注文の確認、照会<br/>
            b)商品発送の確認、照会<br/>
            c)お問合せの返信時
            </p>
            <p>
            当ショップでは、下記の場合を除いてはお客様の断りなく第三者に個人情報を開示・提供することはいたしません。
            </p>
            <p>
            a)法令に基づく場合、及び国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合<br/>
            b)人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合<br/>
            c)当ショップを運営する会社の関連会社で個人データを交換する場合
            </p>
            <h2>4.個人情報の安全管理</h2>
            <p>お客様よりお預かりした個人情報の安全管理はサービス提供会社によって合理的、組織的、物理的、人的、技術的施策を講じるとともに、当ショップでは関連法令に準じた適切な取扱いを行うことで個人データへの不正な侵入、個人情報の紛失、改ざん、漏えい等の危険防止に努めます。
            </p>
            <h2>5.個人情報の訂正、削除</h2>
            <p>お客様からお預かりした個人情報の訂正・削除は下記の問合せ先よりお知らせ下さい。<br/>
            </p>
            <h2>6.cookie(クッキー)の使用について</h2>
            <p>当社は、お客様によりよいサービスを提供するため、cookie （クッキー）を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。
            また、cookie （クッキー）の受け入れを希望されない場合は、ブラウザの設定で変更することができます。<br/>
            ※cookie （クッキー）とは、サーバーコンピュータからお客様のブラウザに送信され、お客様が使用しているコンピュータのハードディスクに蓄積される情報です。
            </p>
            <h2>7.SSLの使用について</h2>
            <p>個人情報の入力時には、セキュリティ確保のため、これらの情報が傍受、妨害または改ざんされることを防ぐ目的でSSL（Secure Sockets Layer）技術を使用しております。<br/>
            ※ SSLは情報を暗号化することで、盗聴防止やデータの改ざん防止送受信する機能のことです。SSLを利用する事でより安全に情報を送信する事が可能となります。
            </p>
            <h2>8.お問合せ先</h2>
            <p>
            特定商取引法に基づく表記をご覧ください。
            </p>
            <h2>9.プライバシーポリシーの変更</h2>
            <p>
            当ショップでは、収集する個人情報の変更、利用目的の変更、またはその他プライバシーポリシーの変更を行う際は、当ページへの変更をもって公表とさせていただきます。
            </p>




          </section>

        </main>
        <Footer />
      </div>
    )
}

export default PrivacyApp
