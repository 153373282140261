import React from "react"
import '../../styles/footer.css'

export default class Footer extends React.Component {

  constructor(){
    super()

    this.state = {

    }
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }


  render(){
    return(<footer>
        <div className="footer-inner">
          <div className="footer-link">
            <a href="https://www.k-sawayaka.org/" target="_blank">運営会社</a>
            <a href="/law">特定商取引に関する表記</a>
            <a href="/privacy">プライバシーポリシー</a>
          </div>
          <div className="copyright">Copyright© SAWAYAKA All Rights Reserved.</div>
        </div>
      </footer>)
  }

}
