import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import Card from '../vo/Card'
import assign from "object-assign"

var CHANGE_EVENT = "card_update" // chenge evnetを定数にする
var CARD_ERROR_EVENT = "card_error" // chenge evnetを定数にする
var CARD_PAIED = "paid"
///Params
var _item = new Card()
var _message

///BODY
var update = (name,value) => {
  _item = _item.set(name,value)
}
var updateMessage = (message) => {
  _message = message
}




var CardStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },

  getMessage(){
    return _message
  },


  ///EMIT/ADD/REMOVE
  emitChange(eventname = CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(eventname)
  },

  addChangeListener(callback, eventname = CHANGE_EVENT){ // リスナーに追加
    this.on(eventname, callback)
  },

  removeChangeListener(callback,eventname = CHANGE_EVENT){ // リスナーから削除
    this.removeListener(eventname, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CARD_UPDATE:
      update(action.name,action.value);
      CardStore.emitChange()
      break;
    case Constants.ERROR_CARD_VALIDATE:
      updateMessage(action.message)
      CardStore.emitChange(CARD_ERROR_EVENT)
      break;
    case Constants.CARD_PAIED:
      CardStore.emitChange(CARD_PAIED)
      break;

    default:
      // noGET_DETAIL
  }
})
export default CardStore
