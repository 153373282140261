import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import CustomerInfo from "../vo/CustomerInfo"

var CHANGE_EVENT = "customerInfo_update" // chenge evnetを定数にする
var SAVE_ON_COOKIE = "save_on_cookie"

///Params

var _item = new CustomerInfo()

var _customter = new CustomerInfo()
var _deliver = new CustomerInfo()

///BODY
/*
var update = (products) => {
  var _plist = []
  products.map((item) =>{
    _plist.push(CartStore.createRecord(item))
  })
  _list = _plist
}*/


var update= (type, name,value) =>{

  switch(type){
    case "customer" :
      _customter =  _customter.set(name,value)
    break;
    case "delivery" :
      _deliver =  _deliver.set(name,value)
    break;
    default:
      _item =  _item.set(name,value)


  }

}

var setCustomer = (item) => {
  if(item){
    _customter = CustomerInfoStore.createRecord(item)
    _item = CustomerInfoStore.createRecord(item)
  }

}

var setDerivery = (item) => {
  if(item){
    _deliver = CustomerInfoStore.createRecord(item)
    _item = CustomerInfoStore.createRecord(item)
  }

}

var updateDetail = (item) => {

  if(item != null){
    _item = CustomerInfoStore.createRecord(item)
  }else{
    _item =  new CustomerInfo()
  }

}

var setDeliveryFromCustomer = () => {
  _deliver = CustomerInfoStore.createRecord(_customter)
}



var CustomerInfoStore = assign({},EventEmitter.prototype,{

  getDetail(){

    return _item
  },
  getCustomer(){
    return _customter
  },
  getDelivery(){
    return _deliver
  },
  createRecord(item){
    return new CustomerInfo(
    {

      id: item.id,
      name:item.name,
      post_code: item.post_code,
      area:item.area,
      tel:item.tel,
      mail:item.mail,
      address:item.address

    })
  },


  ///EMIT/ADD/REMOVE
  emitChange(eventname = CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(eventname)
  },

  addChangeListener(callback, eventname = CHANGE_EVENT){ // リスナーに追加
    this.on(eventname, callback)
  },

  removeChangeListener(callback,eventname = CHANGE_EVENT){ // リスナーから削除
    this.removeListener(eventname, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CUSTOMER_INFO_GET:
      if(action.name === "customer"){
        setCustomer(action.item)
      }else if(action.name === "delivery"){
        setDerivery(action.item)
      }else{
        updateDetail(action.item);
      }

      CustomerInfoStore.emitChange()
      break;
    case Constants.CUSTOMER_INFO_FORM_UPDATE:
      update(action.type, action.name, action.value);
      CustomerInfoStore.emitChange()
      break;
    case Constants.CUSTOMER_INFO_SAVE_COOKIE:
      CustomerInfoStore.emitChange(SAVE_ON_COOKIE)
      break;

    case Constants.SET_DELIVERY_FROM_CUSTOMER:
      setDeliveryFromCustomer()
      CustomerInfoStore.emitChange()
        break;

    default:
      // noGET_DETAIL
  }
})
export default CustomerInfoStore
