import React from "react"
//import CustomerStore from '../../stores/CustomerInfoStore'
import CustomerActions from '../../actions/CustomerActions'
import PrefectureSelection from './PrefectureSelection'

export default class CustomerInfo extends React.Component {

  constructor(){
    super()
    this.updateComment = this.updateComment.bind(this)
    this.state = {
      info:null
    }
    this.props = null
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  upcateForm(e){
    const target = e.target
    CustomerActions.update(this.props.type, target.name,target.value)
  }

  updateForm(name,value){

    CustomerActions.update(this.props.type,name,value)
  }

  updateComment(e){
    this.props.setCommentHandler(e.target.value)
  }

  render(){

    const info = this.props.info
    if(info == null) return null

    return(
      <div className="form">
      <div className="form-box-item">
        <label className="label-def" htmlFor="name">お名前</label>
        <input className="default-input" type="text" name="name" onChange={(e) => this.upcateForm(e)} value={info.name ? info.name :undefined}  placeholder="山田 太郎" />
      </div>


      <div className="form-inline">
        <div className="form-box-item mid">
          <label className="label-def" htmlFor="name">郵便番号</label>
          <input className="default-input" type="text" name="post_code" onChange={(e) => this.upcateForm(e)} value={info.post_code ? info.post_code :undefined } placeholder="436-0033" />
        </div>
      </div>

      <div className="form-inline">
        <div className="form-box-item mid">
          <label className="label-def" htmlFor="name">都道府県</label>
          <PrefectureSelection value={info.area ? info.area :undefined} updateForm={(n,v) => this.updateForm(n,v)　}/>
        </div>
      </div>

      <div className="form-box-item">
        <label className="label-def" htmlFor="name">住所</label>
        <input className="default-input" type="text" name="address" onChange={(e) => this.upcateForm(e)}  value={info.address ? info.address : undefined} placeholder="静岡県掛川市篠場779-2" />
      </div>


      <div className="form-box-item">
        <label className="label-def" htmlFor="name">電話番号</label>
        <input className="default-input" type="text" name="tel" onChange={(e) => this.upcateForm(e)} value={info.tel ? info.tel : undefined} placeholder="0537-62-0620" />
      </div>

        {this.props.type === "customer" ?
      <div className="form-box-item">
        <label className="label-def" htmlFor="name">メール </label>
        <input className="default-input" type="email" name="mail" onChange={(e) => this.upcateForm(e)}  value={info.mail ? info.mail : undefined} placeholder=" info@thehousehayama.com" />
      </div>
      : null
    }

        {this.props.type === "customer" ?
        <div className="form-box-item">
          <label className="label-def" htmlFor="name">コメント </label>
          <textarea name="comment" onChange={this.updateComment}  >{this.props.comment}</textarea>
        </div>
        : null
      }



      </div>

    )
  }


}
