import React, { useState, useEffect,useCallback } from 'react'
import config from 'react-global-configuration';

import Header from "./parts/Header"
import Footer from "./parts/Footer"
import ProductActions from "../actions/ProductActions"
import ProductStore from "../stores/ProductStore"


import "../styles/product.css"
import main_image from "../images/main2.jpg"

function IndexApp(props:PropsValue) {

  const [items, setItems] = useState(null)
  const stableProductChangeHandler = useCallback(productChangeHandler,[])
  useEffect(() => {
    ProductStore.addChangeListener(stableProductChangeHandler)
    ProductActions.getList()

    return function cleanup() {

    };

  },[]);

  function productChangeHandler(){
    ProductStore.removeChangeListener(stableProductChangeHandler)
    setItems(ProductStore.getList().map(item => {
      return (
        <div className="item-box" onClick={() => goToDetail(item.id)}>
          <div className="image"><img src={item.medias[0].url} /></div>
          <div className="title">{item.title}</div>
          <div className="price">¥{Math.round(item.price * config.get("TAX")).toLocaleString() } <span className="sml">(税込)</span></div>
        </div>)
    }))
  }

  function goToDetail(id){
    window.location.href = "/detail/" + id
  }

  return(

    <div className="inner">
      <Header />

      <main className="product">

        <section className="main-content content">
          <div className="main_image"><img src={main_image} alt=""/></div>
          <div className="main-text">SAWAYAKA　SWEETSは、静岡県産の小麦粉や掛川市産無農薬深蒸し茶など材料にこだわった焼き菓子とパンのお店です。<br/> 一つ一つ丁寧に作り上げ包装しています。<br/> 季節によって商品は異なります。季節ごとの味をお楽しみください。</div>
          <div className="product_list">
            {items}
          </div>
        </section>

      </main>
      <Footer />
    </div>
  )
}

export default IndexApp
