import React from "react"

import AuthStore from '../../stores/AuthStore'
import CardStore from '../../stores/CardStore'
import CardActions from '../../actions/CardActions'

export default class PayJp extends React.Component {

  constructor(){
    super()
    this.clickHandler = this.clickHandler.bind(this)
      this.cardStoreChangeHandler = this.cardStoreChangeHandler.bind(this);
      this.cardErrorHandler =this.cardErrorHandler.bind(this)
    this.state = {
      card:null,
      processing:false,
      message:null
    }

  }

  componentDidMount(){
    CardStore.addChangeListener(this.cardStoreChangeHandler)
    CardStore.addChangeListener(this.cardErrorHandler,"card_error")
    this.setState({
      card:CardStore.getDetail()
    })
  }

  upcateForm(e){
    const target = e.target
    CardActions.update(target.name, target.value)
  }


  cardStoreChangeHandler(){
    this.setState({
      card:CardStore.getDetail()
    })
  }
  cardErrorHandler(){
    this.setState({
      message:CardStore.getMessage(),
      processing:false
    })
  }
  clickHandler(){
    this.setState({
      processing:true
    })
    CardActions.pay(AuthStore.getToken(), this.props.trade_id, this.props.total_price, this.state.card, this.props.payment_id)
  }



  render(){
    if(!this.state.card){
      return null
    }


    return(
      <div id="payment_form_body" >
        <div ><i className="fab fa-cc-visa"></i> <i className="fab fa-cc-mastercard"></i> がご利用いただけます。</div>
        <div className="form-box-item">
          <label className="label-def" htmlFor="name">カード番号</label>
          <input className="default-input" type="text" name="cnumber" maxLength="16" size="16" onChange={this.upcateForm} value={this.state.card.cnumber}  placeholder="0123567891234567" />
        </div>
        <div className="form-box-item row">
          <div className="form-box-item-row-item">
            <label className="label-def" htmlFor="name">カード有効期限</label>
            <div className="form-no-wriap"><input className="default-input sml" type="text" name="month" maxLength="2" size="2" onChange={this.upcateForm} value={this.state.card.month}  placeholder="月" />
            &nbsp;/&nbsp;
            <input className="default-input sml" type="text" name="year" maxLength="2" size="2" onChange={this.upcateForm} value={this.state.card.year}  placeholder="年" />
            </div>
          </div>
          <div className="form-box-item-row-item">
            <label className="label-def" htmlFor="name">CVC番号 (3桁または4桁)</label>
            <input className="default-input sml" type="text" name="cvc" maxLength="4" size="4" onChange={this.upcateForm} value={this.state.card.cvc}  placeholder="1234" />
          </div>
        </div>

        <div className="message">{this.state.message}</div>

      </div>
    )
  }


}
