import { Record } from 'immutable'

const Product = Record({
  id: null,
  title: null,
  sub_title: null,
  description:null,
  price:null,
  category:null,
  shop_id:null,
  media:[],
  spec:null
});
export default Product;
