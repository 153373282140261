import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';
import { format } from 'date-fns'

import config from 'react-global-configuration';

import AuthStore from "../stores/AuthStore"
import AuthActions from '../actions/AuthActions';


import CartStore from "../stores/CartStore"
import CartActions from '../actions/CartActions';


import PathActions from '../actions/PathActions';
import PathStore from "../stores/PathStore"
import CustomerInfoStore from '../stores/CustomerInfoStore'
import CustomerActions from "../actions/CustomerActions"

import Trade from '../vo/Trade'
import TradeActions from '../actions/TradeActions';
import ProductStore from '../stores/ProductStore'
import TradeStore from "../stores/TradeStore"
import CardStore from "../stores/CardStore"

import CartItem from './parts/CartItem'
import CustomerInfo from './parts/CustomerInfo'
import PaymentInfo from "./parts/PaymentInfo"
import Header from './parts/Header'
import Footer from './parts/Footer'
import PayJp from "./parts/PayJp"

import  "../styles/cart.css"

  function CartApp(props:PropsValue) {
    const [comment,setComment] = useState(null)
    const [items,setItems] = useState([])
    const [sub_total_price, setSubTotalPrice] = useState(0)
    const [tax, setTax] = useState(0)
    const [total_price, setTotalPrice] = useState(0)
    const [payType, setPayType] = useState(null)
    const [delivery_price, setDeliveryPrice] = useState(0)
    const [message, setMessage] = useState(null)

    const [customer_info, setCustomerInfo] = useState(new CustomerInfo())
    const [delivery_info, setDeliveryInfo] = useState(new CustomerInfo())

    const [btn, setBtn] = useState(
        <button className="button-create" onClick={() => createTrade()}> 購入</button>
    )
    const [prefecture, setPrefecture] = useState(null)
    const stableCarthangeHandler = useCallback(cartChangeHandler,[])
    const stableCustomerChangeHandler = useCallback(customerChangeHandler, [])
    const stableTradeChangeHandler = useCallback(tradeChangeHandler, [])
    useEffect(() => {
      CartStore.addChangeListener(stableCarthangeHandler)
      CartStore.addChangeListener(commentChangeHandler, "comment")
      CardStore.addChangeListener(cardErrorHandler,"card_error")
      CustomerInfoStore.addChangeListener(stableCustomerChangeHandler)
      TradeStore.addChangeListener(stableTradeChangeHandler)
      AuthActions.setAuth(true)
      if(AuthStore.isAuth()){
        CartActions.extractFromCookie(AuthStore.getToken())
      }else{
        AuthActions.createAndExtractFromCookie()
      }
   

      return function cleanup() {
        TradeStore.removeChangeListener(stableTradeChangeHandler)
        CartStore.removeChangeListener(stableCarthangeHandler)
        CartStore.removeChangeListener(commentChangeHandler, "comment")
        CardStore.removeChangeListener(cardErrorHandler,"card_error")
      };

    },[stableCarthangeHandler,stableCustomerChangeHandler,stableTradeChangeHandler]);


    useEffect(() => {
      if(prefecture) {
        setDeliveryPrice(ProductStore.getDeliveryPrice(prefecture))
      }
    },[prefecture]);

    useEffect(() => {
      var total_price = 0
      const _items = CartStore.getList()
      for(var i = 0; i < _items.length; i++){
        total_price += (Number(_items[i].product.price) * Number(_items[i].quantity))
      }
      const _tax = Math.round(total_price * 0.1)
      setTax(_tax)
      setSubTotalPrice(total_price)
      setTotalPrice(total_price + delivery_price + _tax)
    },[delivery_price, items])


    function tradeChangeHandler(){
      setPayType(TradeStore.getPaymentType())
    }


    function cartChangeHandler(){
      const items = CartStore.getList()
      setPayType(TradeStore.getPaymentType())
      setItems(
        items.map(item => {
          if(item.quantity > 0){
            return <CartItem item={item} quantityUpdate = {quantityUpdate} key={"cart_" + item.id} />
          }else{
            return null
          }
        })
      )
    }

    function customerChangeHandler(){
      setCustomerInfo(CustomerInfoStore.getCustomer())
      setDeliveryInfo(CustomerInfoStore.getDelivery())
      setPrefecture(CustomerInfoStore.getDelivery() ? CustomerInfoStore.getDelivery().area : null)
    }

    function cardErrorHandler(){
      setBtn(<button className="button-create" onClick={() => createTrade()}> カード情報を再送</button>)
    }


    function quantityUpdate(id,quantity){
      //console.log(id + "/" + quantity)
      CartActions.updateQuantity(AuthStore.getToken(),id,quantity)
    }

    function back(){
      window.location.href = "/"
    }

    function   clearCart(){
      CartActions.clearCart(AuthStore.getToken())
    }

    function setCommentHandler( value){

      TradeActions.updateComment(value)
    }
    function commentChangeHandler(){
      setComment(CartStore.getComment())
    }

    function checkEmailAddress(str){
      if(str.match(/.+@.+\..+/)==null){
          return false;
      }else{
          return true;
      }
    }
    function createTrade(){
      const customer = CustomerInfoStore.getCustomer()
      const payment = TradeStore.getPayment()

      if(!customer){
        return false
      }
      if(!customer.name ){
        setMessage("お名前を正しく入力してください")
        return false
      }
      if(!customer.area ){
        setMessage("都道府県を正しく入力してください")
        return false
      }
      if(!customer.post_code ){
        setMessage("郵便番号を正しく入力してください")
        return false
      }
      if(!customer.tel ){
        setMessage("電話番号を正しく入力してください")
        return false
      }
      if(!customer.address ){
        setMessage("住所を正しく入力してください")
        return false
      }


      if(!customer.mail || !checkEmailAddress(customer.mail)){
        setMessage("メールアドレスを正しく入力してください")
        return false
      }

      const delivery = CustomerInfoStore.getDelivery()
      if(!delivery){
        return false
      }
      if(!delivery.name ){
        setMessage("お名前を正しく入力してください")
        return false
      }
      if(!delivery.area ){
        setMessage("都道府県を正しく入力してください")
        return false
      }
      if(!delivery.post_code ){
        setMessage("郵便番号を正しく入力してください")
        return false
      }
      if(!delivery.tel ){
        setMessage("電話番号を正しく入力してください")
        return false
      }
      if(!delivery.address ){
        setMessage("住所を正しく入力してください")
        return false
      }


      //const payment_method_id = Number(TradeStore.getPaymentMethodId())
      if(!payment || !payment.id ){
        setMessage("支払い方法を決定してください。")
        return false
      }

      if(payment.pay_type === 2){
        const card = CardStore.getDetail()
        if(!card.cnumber){
          setMessage("カード情報を正しく入力してください")
          return false
        }
        if(!card.cvc){
          setMessage("カード情報(cvc)を正しく入力してください")
          return false
        }
        if(!card.month || !card.year ){
          setMessage("カード情報(有効期限)を正しく入力してください")
          return false
        }
      }


    setBtn(<div>Loading...</div>)
      var cart_ids = []
      var total_price = 0;
      const _items =  CartStore.getList()
      for(var i = 0; i < _items.length; i++){
        cart_ids.push(_items[i].id)
        total_price += (Number(_items[i].product.price) * Number(_items[i].quantity))
      }

      const _product_total_price = total_price
      const _tax = Math.round(total_price * 0.1)
      const _delivery_price = ProductStore.getDeliveryPrice(delivery.area)
      const _total_price =( _product_total_price + _tax + _delivery_price)

      const trade =  Trade({
        cart:cart_ids,
        shop_id:Number(config.get("SHOP_ID")),
        shipping_info:delivery,
        customer_info:customer,
        total_price:Number(_total_price),
        delivery_id:Number(ProductStore.getDeliveryMethod()[0].id),
        payment_id:payment.id,
        product_total_price:Number(_product_total_price),
        tax:Number(_tax),
        delivery_price:Number(_delivery_price),
        status:1,
        comment:CartStore.getComment()
      })

    TradeActions.save(AuthStore.getToken(), trade)

    }

    function setDeliveryFromCustomer(){
      CustomerActions.setDeliveryFromCustomer()
    }

    return(
      <div className="inner">
      <Header />

      <main className="cart">
        <div className="cart-column">
          <section className="cart-content product-info">
            <h2>注文内容</h2>
            <table className="order-list">
                <tbody>
                <tr className="is-pc"><th>商品名</th><th className="t-right">単価</th><th className="t-right">数量</th><th className="t-right">小計</th></tr>
                  {items}
                </tbody>
            </table>
            <div className="total_price">商品合計(税抜き) : ￥{sub_total_price.toLocaleString()}円</div>
            <div className="total_price">消費税 : ￥{tax.toLocaleString()}円</div>
            <div className="total_price">送料 : ￥{delivery_price ? delivery_price.toLocaleString() : "-"}円</div>
            <div className="total_price">合計金額 : ￥{delivery_price ?　total_price.toLocaleString() : null }円</div>
          </section>

          <section className="cart-content">
            <h2>お客様情報</h2>
            <CustomerInfo info={customer_info}  type="customer" comment={comment} setCommentHandler={setCommentHandler} />

            <h2>送付先</h2>
            <div  class="link right" onClick={setDeliveryFromCustomer}> <i class="far fa-clone"></i> お客様情報からコピーする</div>
            <CustomerInfo info={delivery_info} type="delivery" />
            <h2>支払い方法</h2>
            <PaymentInfo />

            <h2>支払い金額</h2>
            <div className="total_price">商品合計(税込み) : ￥{sub_total_price.toLocaleString()}円</div>
            <div className="total_price">消費税 : ￥{tax.toLocaleString()}円</div>
            <div className="total_price">送料 : ￥{delivery_price ? delivery_price.toLocaleString() : "-"}円</div>
            <div className="total_price">合計金額 : ￥{delivery_price ? total_price.toLocaleString() : "-"}円</div>
            {
              payType === 2 ? <h2>クレジットカード情報</h2> : null
            }
            {
              payType === 2? <PayJp /> : null
            }



            <div className="button-box">
              <div className="message">{message}</div>
              {btn}
              <button className="white-btn"　onClick={clearCart}>カートの内容をクリアする</button>
              <div onClick={ back}>トップに戻る</div>
            </div>

          </section>


        </div>



      </main>
     <Footer />
    </div>
    )
}

export default CartApp
