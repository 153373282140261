import { Record } from 'immutable'

const Trade = Record({
  id:null,
  cart:null,
  delivery_id:null,
  shop_id:null,
  shipping_info:null,
  customer_info:null,
  payment_id:null,
  payment_method:null,
  total_price:null,
  created_at:null,
  product_total_price:null,
  delivery_price:null,
  tax:null,
  status:null,
  payments:[],
  products:[],
  comment:null
});
export default Trade;
