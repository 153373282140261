import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Cart from "../vo/Cart"
import ProductStore from "../stores/ProductStore"
import PaymentMethod from "../vo/PaymentMethod"

var CHANGE_EVENT = "cart_update" // chenge evnetを定数にする
var COMMENT = "comment"
///Params
var _list = [] // 初期化
var _shop_id
var _comment
///BODY
var update = (products) => {

  const _plist = products.map((item) =>{
    return CartStore.createRecord(item)
  })
  _list = _plist
}
var setShopId = (shop_id) => {
  _shop_id = shop_id
}

var setComment = (c) => {
  _comment = c
}

var clear = () =>{
  _list = []
}




var CartStore = assign({},EventEmitter.prototype,{

  getList(){
    return _list
  },
  getShopId(){
    return _shop_id
  },
  getComment(){
    return _comment
  },
  createPaymentMedhoRecord(item){
    return new PaymentMethod({
      id: item.id,
      pay_type: item.pay_type,
      name: item.name
    })
  },

  createRecord(item){
    return new Cart(
    {

      id: item.id,
      product_id:item.product_id,
      shop_id: item.shop_id,
      x_index:item.x_index,
      y_index:item.y_index,
      x_name:item.x_name,
      y_name:item.y_name,
      quantity:item.quantity,
      product:ProductStore.createRecord(item.product),
      //payment_method:CartStore.createPaymentMedhoRecord(item.payment_method)
    })
  },


  ///EMIT/ADD/REMOVE
  emitChange(eventname = CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(eventname)
  },

  addChangeListener(callback, eventname = CHANGE_EVENT){ // リスナーに追加
    this.on(eventname, callback)
  },

  removeChangeListener(callback,eventname = CHANGE_EVENT){ // リスナーから削除
    this.removeListener(eventname, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CART_UPDATE:
      update(action.list);
      setShopId(action.shop_id)
      CartStore.emitChange()
      break;
    case Constants.CART_SAVE_ON_COOKIE:
      CartStore.emitChange()
      break;
    case Constants.CART_CLEAR:
      clear()
      CartStore.emitChange()
      break;
    case Constants.UPDATE_COMMENT:

      setComment(action.comment)
      CartStore.emitChange(COMMENT)
      break;



    default:
      // noGET_DETAIL
  }
})
export default CartStore
