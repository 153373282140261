import React, { useState, useEffect,useCallback } from 'react'
import { useParams} from 'react-router-dom';
import { format } from 'date-fns'

import config from 'react-global-configuration';
import AuthActions from "../actions/AuthActions"
import AuthStore from "../stores/AuthStore"
import TradeStore from "../stores/TradeStore"
import TradeActions from '../actions/TradeActions';


import Header from './parts/Header'
import Footer from './parts/Footer'

import  "../styles/cart.css"

function SuccessApp(props:PropsValue) {
    const { id } = useParams();
    const [item, setItem] = useState(null)
    const [items_html, setItemHtml] = useState(null)
    const [total_price, setTotalItem] = useState(0)
    const [delivery_price, setDeliveryPrice] = useState(0)

    const stableTradeChangeHandler = useCallback(tradeChangeHandler,[])

    useEffect(() => {
      AuthActions.setAuth()
      TradeStore.addChangeListener(stableTradeChangeHandler)
      TradeActions.getDetail(AuthStore.getToken(), id)

      return function cleanup() {

      };

    },[stableTradeChangeHandler]);


    function tradeChangeHandler(){
      TradeStore.removeChangeListener(stableTradeChangeHandler)
      const _item = TradeStore.getItem()
      setItem(_item)
      setItemHtml(
        _item.products.map(item => {
          if(item.quantity > 0){
            return (
              <tr key={"tr_" + item.id} >
                <td>{item.product.title}</td>
                <td className="t-right"><span className="is-mob">単価: </span>{item.product.price.toLocaleString()}円</td>
                <td className="t-right quantity"><span className="is-mob">数量: </span>{item.quantity}{item.product.unit}</td>
                <td className="t-right"><span className="is-mob">小計: </span>{(item.quantity*item.product.price).toLocaleString()}円</td>
              </tr>
            )
          }else{
            return null
          }
        }
      ))
    }


    return(
      <div className="inner">
        <Header />

        <main className="cart">

          <section className="cart-content product-info">
            <h2>注文完了</h2>

            <p>以下の注文が完了いたしました。<br/>ご注文ありがとうございました。発送までしばらくお待ちください。</p>

          </section>


          <section className="cart-content product-info">
            <h2>注文内容</h2>
            <table className="order-list">
                <tbody>
                <tr className="is-pc"><th>商品名</th><th className="t-right">単価</th><th className="t-right">数量</th><th className="t-right">小計</th></tr>
                  {items_html}
                </tbody>
            </table>

            <div className="total_price">商品合計(税抜き) : ￥{item ? item.product_total_price.toLocaleString() : null}円</div>
            <div className="total_price">消費税 : ￥{item ? item.tax.toLocaleString() : null}円</div>
            <div className="total_price">送料 : ￥{item ? item.delivery_price.toLocaleString() : null}円</div>
            <div className="total_price">合計金額 : ￥{item ? item.total_price.toLocaleString() : null }円</div>


            <h2>お客様情報</h2>
            <div className="form">

              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">お名前</label>
                <div className="value">{item ? JSON.parse(item.customer_info).name : null}</div>
              </div>


                <div className="form-box-item-static">
                  <label className="label-def" htmlFor="name">郵便番号</label>
                  <div className="value">{item ? JSON.parse(item.customer_info).post_code : null}</div>
                </div>



                <div className="form-box-item-static">
                  <label className="label-def" htmlFor="name">都道府県</label>
                  <div className="value">{item ? config.get("PREF_" + JSON.parse(item.customer_info).area) : null}</div>
                </div>


              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">住所</label>
                <div className="value">{item ? JSON.parse(item.customer_info).address : null}</div>
              </div>
              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">電話番号</label>
                <div className="value">{item ? JSON.parse(item.customer_info).tel : null}</div>
              </div>
              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">メール </label>
                <div className="value">{item ? JSON.parse(item.customer_info).mail : null}</div>
              </div>
              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">コメント </label>
                <div className="value">{item ? JSON.parse(item.customer_info).comment : null}</div>
              </div>
            </div>


            <h2>送付先</h2>


            <div className="form">

              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">お名前</label>
                <div className="value">{item ? JSON.parse(item.shipping_info).name : null}</div>
              </div>


                <div className="form-box-item-static">
                  <label className="label-def" htmlFor="name">郵便番号</label>
                  <div className="value">{item ? JSON.parse(item.shipping_info).post_code : null}</div>
                </div>



                <div className="form-box-item-static">
                  <label className="label-def" htmlFor="name">都道府県</label>
                  <div className="value">{item ? config.get("PREF_" + JSON.parse(item.shipping_info).area) : null}</div>
                </div>


              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">住所</label>
                <div className="value">{item ? JSON.parse(item.shipping_info).address : null}</div>
              </div>
              <div className="form-box-item-static">
                <label className="label-def" htmlFor="name">電話番号</label>
                <div className="value">{item ? JSON.parse(item.shipping_info).tel : null}</div>
              </div>

            </div>


            <h2>支払い方法</h2>

            <div className="form-box-item-static">
              <div className="value">{item ? item.payment_method.name : null}{item && item.payments.length > 0 && item.payments[0].status === 1 ? " (支払済)" : null }</div>
            </div>

          </section>



        </main>
        <Footer />
      </div>
    )
}

export default SuccessApp
