import { Record } from 'immutable'

const CustomerInfo = Record({
  id: null,
  name: null,
  area:null,
  post_code: null,
  tel: null,
  mail:null,
  address:null,
  nick_name:null
});
export default CustomerInfo;
