import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


var CHANGE_EVENT = "cart_update" // chenge evnetを定数にする

///Params
var _path = null // 初期化

///BODY
var update = (path) => {
   _path = path
}




var PathStore = assign({},EventEmitter.prototype,{

  getPath(){
    return _path
  },


  ///EMIT/ADD/REMOVE
  emitChange(eventname = CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(eventname)
  },

  addChangeListener(callback, eventname = CHANGE_EVENT){ // リスナーに追加
    this.on(eventname, callback)
  },

  removeChangeListener(callback,eventname = CHANGE_EVENT){ // リスナーから削除
    this.removeListener(eventname, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PATH_UPDATE:
      update(action.path);
      PathStore.emitChange()
      break;
    case Constants.PATH_SAVE_ON_COOKIE:
      PathStore.emitChange()
      break;




    default:
      // noGET_DETAIL
  }
})
export default PathStore
